html {
  height: 100%;
}
body {
  background-color: white;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
  font-size: 12px;
}
header {
  padding: 0.3em;
  border-bottom: 1px solid #eee;
}
canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#textareas {
  position: relative;
  flex-grow: 1;
  display: flex;
  min-height: 0;
}
#left,
#right {
  display: flex;
  flex-direction: column;
  min-height: 0;
  width: 50%;
}
#original,
#generated {
  width: 100%;
  flex-grow: 1;
  min-height: 0;
}
#right {
  right: 0;
  border-left: 1px solid #eee;
}
.pane-header {
  padding: 0.3em;
}
.pane-footer {
  padding: 0.3em;
  white-space: nowrap;
  overflow: hidden;
}
.covered {
  background-color: #daead1;
}
.hovered {
  background-color: #fbedcc;
}
.filename-line {
  font-size: 10px;
  line-height: 15px;
  padding-left: 1em;
  background-color: azure;
  white-space: nowrap;
  overflow: hidden;
}
